






import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({
  name: ''
})
export default class Error extends Vue {
  @Prop({ default: '' })
  type!: 'noNetwork' | 'serverError' | 'noResult' | 'noContent' | 'noCoupon';

  @Prop({ default: '' })
  src!: string;

  @Prop({ default: '' })
  text!: string;

  errorList = {
    // 网络连接失败
    noNetwork: {
      img: '//means-static.luckyop.com/images/20211022/1fijcm0ffngcfgdvebeg.png',
      label: '网络连接失败'
    },
    // 服务器异常
    serverError: {
      img: '//means-static.luckyop.com/images/20211022/1fijcmdlrngn849jl0j.png',
      label: '服务器异常'
    },
    // 找不到结果
    noResult: {
      img: '//means-static.luckyop.com/images/20211022/1fijcmr5mbiikpe96dko.png',
      label: '没有找到匹配结果'
    },
    // 无内容
    noContent: {
      img: '//means-static.luckyop.com/images/20211022/1fijcn6qogls11iv7bg.png',
      label: '暂无内容'
    },
    // 无卡券
    noCoupon: {
      img: '//means-static.luckyop.com/images/20211022/1fijcnriicpkkavr8te.png',
      label: '暂无可用优惠券'
    }
  };
}
