




















import { typeQueryByPageFn } from '@/api/type/tool';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Error from '@/components/Error/index.vue';
@Component({
  name: 'BaseList',
  components: { Error }
})
export default class extends Vue {
  loading = false;
  finished = false;

  current = 1;
  size = 10;

  list: any[] = [];

  // 查询条件
  @Prop({ type: Object, default: () => ({}) })
  param!: Record<string, string>;

  @Prop({ required: true })
  api!: typeQueryByPageFn;

  @Prop({ default: false })
  immediateCheck!: boolean;

  async onLoad() {
    const { payload } = await this.api(this.param, this.current, this.size);
    if (this.current == 1) {
      this.list = [];
    }
    this.list.push(...payload.records);

    this.loading = false;
    this.finished = this.current >= payload.pages;
    this.current++;
  }

  reload() {
    console.log(2);

    this.loading = true;
    this.current = 1;
    this.finished = false;
    this.$nextTick(this.onLoad);
  }
}
