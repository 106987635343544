import { GetPromiseReturn } from './../type/base';
import request, { baseQueryByPage } from '@/utils/request';
import { IRequestResult } from '../type/tool';
import { IBaseAttr, IBaseProduct, IBaseStand } from '../type/base';

// 查询商品列表
export const getProList = baseQueryByPage<
  {
    // 商品名称模糊查询
    spuName?: string;
    // 单个分类id
    catId?: number;
    // 大分类
    catPid?: number;
    // 分类id列表
    catIdList?: string[];
    // 商品id列表
    spuIdList?: number[];
    // 单个商品id
    spuId?: number;
    // 标签
    tagCode?: string;
    // 排序字段名
    sortField?: string;
    // 是否降序
    desc?: boolean;
  },
  IBaseProduct
>('/product/spu/list');

export type ICategoryInfo = {
  // 分类Id
  catId: number;
  // 分类树级别
  catLevel: number;
  // 子
  children: ICategoryInfo[];
  // 其他配置
  config: string;
  // 图标
  icon: string;
  // 商户Id
  merchantId: string;
  // 分类名称
  name: string;
  // 父级分类Id
  parentCid: number;
  // 计量单位
  productUnit: string;
  // 显示状态
  showStatus: boolean;
  // 排序
  sort: number;
};

// 获取商品分类
export function getCategory(): Promise<IRequestResult<ICategoryInfo[]>> {
  return request.post('/product/category/list/tree', {}, { hideMsg: true });
}

// 查询子分类
export function getCategoryChildren(catId: number): Promise<IRequestResult<ICategoryInfo>> {
  return request.post(`/product/category/listChildren/${catId}`, {}, { hideMsg: true });
}

// 查询商品的销售属性列表
export function getSaleAttr(
  spuId: IBaseProduct['spuId']
): Promise<IRequestResult<Pick<IBaseProduct, 'usableInventory' | 'inputRules' | 'inventory' | 'maxDiscountPrice' | 'maxPrice' | 'minDiscountPrice' | 'minPrice' | 'imgUrl' | 'spuName'> & ISaleAttr>> {
  return request.get(`/product/spu/saleAttr/${spuId}`, { hideMsg: true });
}

export type ISaleAttrData = GetPromiseReturn<typeof getSaleAttr>['payload'];

// 根据规格id 查询信息
export function getSkuIdList(skuIds: Array<IBaseStand['skuId']>): Promise<
  IRequestResult<
    Array<
      IBaseStand & {
        productSpu: IBaseProduct;
        saleAttrList: IBaseAttr[];
      }
    >
  >
> {
  return request.post('/product/sku/listByIdList', skuIds, { hideMsg: true, loading: true, loadingText: '' });
}

// 查询商品的销售属性列表
export type ISaleAttr = {
  saleAttrList: (Pick<IBaseAttr, 'attrId' | 'attrName'> & { attrValueList: any[] })[];
  skuList: (Omit<IBaseStand, 'createTime' | 'skuDesc' | 'updateTime'> & {
    saleAttrList: Pick<IBaseAttr, 'attrId' | 'attrName' | 'attrSort' | 'attrValue' | 'id' | 'skuId' | 'spuId'>[];
  })[];
  spuName: string;
};

// 查询商品详情
export function getProDetail(spuId: IBaseProduct['spuId']): Promise<IRequestResult<IBaseProduct & ISaleAttr>> {
  return request.get(`/product/spu/info/${spuId}`, { hideMsg: true, loading: true, loadingText: '' });
}

// 收藏
export function saveFavorites(data: Array<IBaseProduct['spuId']>): Promise<IRequestResult> {
  return request.post('/user/favorites/star', data, { msgText: '收藏成功', loadingText: '', loading: true });
}

// 取消收藏
export function cancelFavorites(data: Array<IBaseProduct['spuId']>): Promise<IRequestResult> {
  return request.post('/user/favorites/unstar/spu', data, { msgText: '取消收藏成功', loadingText: '', loading: true });
}

// 查询所有收藏
// export function getFavorites(data: { current: number; size: number }): Promise<IRequestResult<IBaseCollect[]>> {
//   return request.post('/user/favorites/list', data, { hideMsg: true });
// }

export const getFavorites = baseQueryByPage<{ current: number; size: number }>('/user/favorites/list');

// 查询商品是否收藏
export function selectIsFavorites(spuId: number): Promise<IRequestResult> {
  return request.get(`/user/favorites/get/${spuId}`, { hideMsg: true });
}

// 获取优惠券分类列表
export function getCouponCategoryList(couponNumber: string): Promise<IRequestResult<ICategoryInfo[]>> {
  return request.post(`/product/spu/couponCategoryList/${couponNumber}`, {}, { hideMsg: true });
}

// 查询优惠券商品列表
export const getCouponProList = function (couponNumber: string) {
  return baseQueryByPage<{ current: number; size: number }>(`/product/spu/listCoupon/${couponNumber}`);
};

/* 根据优惠券 卡号卡密 返回对应商品 */
export function getCouponInfo(data: { couponNumber: string; couponPassword: string }): Promise<
  IRequestResult<
    Pick<IBaseStand, 'skuId'> & {
      productSpu: IBaseProduct;
      saleAttrList: Pick<IBaseAttr, 'attrId' | 'attrName' | 'attrSort' | 'attrValue' | 'id' | 'skuId' | 'spuId'>[];
    }
  >
> {
  return request.post('/marketing/coupon/front/validity/fixed/coupon', data, { hideMsg: true, loadingText: '', loading: true });
}
// 查询商品热销榜单
export function getProRank(): Promise<IRequestResult> {
  return request.get('/product/spu/rank', { hideMsg: true });
}
