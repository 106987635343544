




















import { IBaseProduct } from '@/api/type/base';
import { calcDeductCredit } from '@/utils/math';
import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({
  name: ''
})
export default class extends Vue {
  @Prop({ required: true })
  // 是否是第一行
  innerData!: IBaseProduct;

  get credit() {
    return calcDeductCredit(this.innerData);
  }
}
